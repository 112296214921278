<!--
 * @Author: xiaziwen
 * @Date: 2021-01-13 15:01:41
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-13 15:18:42
 * @FilePath: \crm-education\src\views\common\login\forget.vue
-->
<template>
  <div class="forget main-width">
    <p class="text-left back-btn link hover-color-blue" @click="toLogin">返回登录</p>
    <div class="forget-content form-content" v-if="step === 1">
      <p class="fs-24 fw-bold mb-10">找回密码</p>
      <p class="fs-12 color-gray mb-15">验证码将会发送至你的注册手机</p>
      <div class="form-wrap">
        <a-form :form="verifyForm" @submit="getValidate">
          <a-form-item>
            <a-input placeholder="请输入手机号" v-decorator="verifyFormRule.phoneNumber"></a-input>
          </a-form-item>
          <a-form-item>
            <a-row :gutter="10">
              <a-col :span="15">
                <a-input placeholder="请输入验证码" v-decorator="verifyFormRule.code"></a-input>
              </a-col>
              <a-col :span="9">
                <count-down ref="countDown" @send="handleSend"></count-down>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item>
            <a-button class="cus-button" block type="primary" html-type="submit" :loading="btnLoading">下一步</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="forget-content form-content" v-if="step === 2">
      <p class="fs-24 fw-bold mb-10">设置新密码</p>
      <p class="fs-12 color-gray mb-15">新密码应不少于8位</p>
      <div class="form-wrap">
        <a-form :form="resetForm" @submit="submitNewPassword">
          <a-form-item>
            <a-input placeholder="新密码" v-decorator="resetPasswordFormRule.newPassword" type="password"></a-input>
          </a-form-item>
          <a-form-item>
            <a-input
              placeholder="请输入新密码"
              v-decorator="resetPasswordFormRule.confirmNewPassword"
              type="password"
            ></a-input>
          </a-form-item>
          <a-form-item>
            <a-button class="cus-button" type="primary" block html-type="submit" :loading="btnLoading"
              >重置密码</a-button
            >
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="forget-content" v-if="step === 3">
      <div class="forget-success">
        <a-icon type="check-circle" theme="twoTone" two-tone-color="#52c41a" style="fontSize:80px;" />
        <p class="fs-24 fw-bold mb-10 mt-10">密码修改成功</p>
        <a-button type="primary" @click="toLogin">立即登录</a-button>
      </div>

      <!-- <p class="fs-12 link color-blue mb-15" @click="toLogin">立即登录</p> -->
      <!-- <div class="form-wrap">
        <div class="img-wrap">
          <img src="" alt="修改成功" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import md5 from 'md5'
import CountDown from '@/components/CountDown'
import { isPhone, isPassword, isSmsCode } from '@/utils/validate'
import api from '@/api'
export default {
  name: 'forget',
  components: { CountDown },
  data() {
    return {
      step: 1,
      validateStatus: 0,
      btnLoading: false,
      organizationId: localStorage.getItem('organizationId'),
      phoneNumber: '',
      verifyFormRule: {
        phoneNumber: [
          'phoneNumber',
          {
            rules: [{ required: true, message: '手机号不能为空' }, { validator: this.validatePhone }],
            initialValue: ''
          }
        ],
        code: [
          'code',
          {
            rules: [{ required: true, message: '请输入验证码' }, { validator: this.validateSms }],
            initialValue: ''
          }
        ]
      },
      resetPasswordFormRule: {
        newPassword: [
          'newPassword',
          {
            rules: [{ required: true, message: '密码不能为空' }, { validator: this.validatePass }],
            initialValue: ''
          }
        ],
        confirmNewPassword: [
          'confirmNewPassword',
          {
            rules: [{ required: true, message: '请确认新密码' }, { validator: this.validatePass2 }],
            initialValue: ''
          }
        ]
      }
    }
  },
  beforeCreate() {
    this.verifyForm = this.$form.createForm(this, { name: 'verifyForm' })
    this.resetForm = this.$form.createForm(this, { name: 'resetForm' })
  },
  methods: {
    getValidate(e) {
      e.preventDefault()
      this.verifyForm.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          await this.checkSms()
        }
      })
    },
    onConfirm() {
      this.step = 3
    },
    toLogin() {
      this.$router.replace({ name: 'login_student' })
    },
    validatePhone(rule, value, callback) {
      const form = this.verifyForm
      const phone = form.getFieldValue('phoneNumber')
      if (value && !isPhone(phone)) {
        callback('手机号格式不正确')
      } else {
        callback()
      }
    },
    validateSms(rule, value, callback) {
      const form = this.verifyForm
      const code = form.getFieldValue('code')
      if (value && !isSmsCode(code)) {
        callback('验证码格式不正确')
      } else {
        callback()
      }
    },

    validatePass(rule, value, callback) {
      const form = this.resetForm
      const confirmPassword = form.getFieldValue('confirmPassword')
      if (!isPassword(value)) {
        callback(new Error('密码由8-12位字母和数字组成'))
      } else {
        if (confirmPassword !== '') {
          form.validateFields(['confirmPassword'])
        }
        callback()
      }
    },
    validatePass2(rule, value, callback) {
      const form = this.resetForm
      const newPassword = form.getFieldValue('newPassword')
      if (value !== newPassword) {
        callback(new Error('2次输入密码不匹配'))
      } else {
        callback()
      }
    },
    handleSend() {
      this.verifyForm.validateFields(['phoneNumber'], async (err, values) => {
        if (!err) {
          // 发送验证码
          await this.$refs['countDown'].countDown()
          await this.sedSms()
        }
      })
    },
    async sedSms() {
      try {
        const phoneNumber = this.verifyForm.getFieldValue('phoneNumber')
        await api.student.sendSms(this.organizationId, phoneNumber)
      } catch (e) {}
    },
    async checkSms() {
      try {
        this.btnLoading = true
        const phoneNumber = this.verifyForm.getFieldValue('phoneNumber')
        const code = this.verifyForm.getFieldValue('code')
        await api.student.checkSmsCode(phoneNumber, code)
        this.btnLoading = false
        this.phoneNumber = phoneNumber
        this.step = 2
      } catch (e) {
        this.btnLoading = false
      }
    },
    async submitNewPassword(e) {
      e.preventDefault()
      this.resetForm.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          try {
            this.btnLoading = true
            const newPassword = this.resetForm.getFieldValue('newPassword')
            const { phoneNumber, organizationId } = this
            await api.student.submitPassword({
              newPassword: md5(newPassword),
              phoneNumber: phoneNumber,
              organizationCode: organizationId
            })
            this.btnLoading = false
            this.onConfirm()
          } catch (e) {
            this.btnLoading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.forget {
  padding: 30px 0;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;

  .back-btn {
    padding-left: 20px;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      background: url('../../../assets/img/left-arrow.png') no-repeat center center;
      position: absolute;
      left: 0;
      top: 2px;
    }
  }
  .forget-content {
    padding: 50px;
    margin: 50px auto;
    .forget-success {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &.form-content {
      width: 400px;
    }
    .ant-form-item {
      margin-bottom: 10px;
    }
  }
}
</style>
